import React from 'react';

const Logo = () => {
    return (
        <svg width="52" height="83" viewBox="0 0 52 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.45 32.4V51L28.8 39.59L0 21.39V0L51.45 32.4ZM42.56 56.53L0 83V61.62L31.13 42.35L42.56 56.53Z" fill="black"/>
        </svg>
    );
};

export default Logo;