import React, {useEffect} from 'react';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import image from '../../../../dist/images/bg/pexels-alena-shekhovtcova-8067769.jpg';

gsap.registerPlugin(ScrollTrigger);

const Contacts = () => {
    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 991px)" : function(){
                gsap.from(".iit-ui-home-contact-content__image", {
                    y: 400,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__contact",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-contact-subtext__text", {
                    y: 100,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__contact",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-contact-content__title h1", {
                    y: 300,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__contact",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-contact-content__paragraph p", {
                    y: 300,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__contact",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
            }
        })
    }, []);
    return (
        <section id='contact' className='iit-ui-home__contact'>
             <div className='iit-ui-home-contact__container'>
                <div className='iit-ui-home-contact__subtext'>
                    <div className='iit-ui-home-contact-subtext__text'>  
                        <span>Contacts</span>
                    </div>
                    <div className='iit-ui-home-contact-subtext__text'>  
                        <span>Contacts</span>
                    </div>
                    <div className='iit-ui-home-contact-subtext__text'>  
                        <span>Contacts</span>
                    </div>
                </div>
                <div className='iit-ui-home-contact__content'>
                    <div className='iit-ui-home-contact-content__image'>
                        <img src={image} alt="" />
                    </div>
                    <div className='iit-ui-home-contact-content__text'>
                        <div className='iit-ui-home-contact-content__title'>
                            <h1>Nous contacter</h1>
                        </div>
                        <div className='iit-ui-home-contact-content__paragraph'>
                            <p>Vous avez besoin de nos services. Veuillez nous contacter</p>
                            <p>Tel: <a href="tel:+22625456461">(+226) 25 45 64 61</a></p>
                            <p>Email: <a href="mailto:info@iventit.com">info@iventit.com</a></p>
                            <p>Ouagadougou, BURKINA FASO</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contacts;