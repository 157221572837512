import React, {useEffect} from 'react';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import image from '../../../../dist/images/bg/pexels-ketut-subiyanto-4559589.jpg';
gsap.registerPlugin(ScrollTrigger);
const Objectif = () => {
    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 991px)" : function(){
                gsap.from(".iit-ui-home-objectif-content__image", {
                    y: 400,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__objectif",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-objectif-subtext__text", {
                    y: -100,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__objectif",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-objectif-content__title h1", {
                    y: 300,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__objectif",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-objectif-content__paragraph p", {
                    y: 300,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__objectif",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
            }
        })
    }, []);
    return (
        <section className='iit-ui-home__objectif' id='objectif'>
            <div className='iit-ui-home-ojectif__container'>
                <div className='iit-ui-home-objectif__subtext'>
                    <div className='iit-ui-home-objectif-subtext__text'>  
                        <span>Objectif</span>
                    </div>
                    <div className='iit-ui-home-objectif-subtext__text'>  
                        <span>Objectif</span>
                    </div>
                    <div className='iit-ui-home-objectif-subtext__text'>  
                        <span>Objectif</span>
                    </div>
                </div>
                <div className='iit-ui-home-objectif__content'>
                    <div className='iit-ui-home-objectif-content__image'>
                        <img src={image} alt="" />
                    </div>
                    <div className='iit-ui-home-objectif-content__text'>
                        <div className='iit-ui-home-objectif-content__title'>
                            <h1>Notre Objectif</h1>
                        </div>
                        <div className='iit-ui-home-objectif-content__paragraph'>
                            <p>Accompagner et former nos clients, du conseils a la réalisation dans les domaines de l’infrastructure, le développement d’applications, le portail et le collaboratif, la data et le décisionnel</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Objectif;