import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
const Loader = ({setLoading}) => {
    useEffect(() => {
        const timer = setTimeout(() => {setLoading(false)}, 3800);
        return () => clearTimeout(timer);
    })
    return (
        <div className='iit-ui-loader'>
            <div className='iit-ui-loader__container'>
                <div className='iit-ui-loader__text'>
                    <motion.h1
                    initial={{y: 80, opacity: 0}}
                    animate={
                        {
                            y: 0, 
                            opacity: 1,
                            transition: {
                                ease: [0.6, 0.01, -0.25, 0.95],
                                duration: 1.5,
                                // delay: 4.5
                            },
                        
                        }
                    }
                    >IT FOR ALL</motion.h1>
                </div>
                <div className='iit-ui-loader__text'>
                    <motion.p
                    initial={{y: 80, opacity: 0}}
                    animate={
                        {
                            y: 0, 
                            opacity: 1,
                            transition: {
                                ease: [0.6, 0.01, -0.25, 0.95],
                                duration: 1.5,
                                delay: 0.9
                            },
                        
                        }
                    }
                    >Page en cours de chargement</motion.p>
                </div>
            </div>
        </div>
    );
};

export default Loader;