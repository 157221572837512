import React from 'react';
import { motion } from 'framer-motion';
import Logo from '../../../dist/icons/Logo';

const Header = () => {
    return (
        <header className='iit-ui-header'>
            <div className='iit-ui-header__container'>
                <motion.div
                initial={{y: -10, opacity: 0}}
                animate={
                    {
                        y: 0, 
                        opacity: 1,
                        transition: {
                            ease: [0.6, 0.01, -0.25, 0.95],
                            duration: 1.5,
                            delay: 2.9
                        },
                    
                    }
                }
                className='iit-ui-header__logo'>
                    <Logo/>
                </motion.div>
                <div className='iit-ui-header__navigation'>
                    <ul className='iit-ui-header-navigation__list'>
                        <motion.li 
                        initial={{y: -10, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 2.6
                                },
                            
                            }
                        }
                        className='iit-ui-header-navigation__item'>
                            <a href="#accueil">
                                Accueil
                            </a>
                        </motion.li>
                        <motion.li 
                        initial={{y: -10, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 2.6
                                },
                            
                            }
                        }
                        className='iit-ui-header-navigation__item'>
                            <a href="#about">
                                A propos de nous
                            </a>
                        </motion.li>
                        <motion.li 
                        initial={{y: -10, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 2.3
                                },
                            
                            }
                        }
                        className='iit-ui-header-navigation__item'>
                            <a href="#objectif">
                                Objectif
                            </a>
                        </motion.li>
                        <motion.li 
                        initial={{y: -10, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 1.7
                                },
                            
                            }
                        }
                        className='iit-ui-header-navigation__item'>
                            <a href="#services">
                                Services
                            </a>
                        </motion.li>
                    </ul>
                </div>
                <motion.div 
                initial={{y: -10, opacity: 0}}
                animate={
                    {
                        y: 0, 
                        opacity: 1,
                        transition: {
                            ease: [0.6, 0.01, -0.25, 0.95],
                            duration: 1.5,
                            delay: 1.4
                        },
                    }
                }
                className='iit-ui-header__action'>
                    <a href="#contact">
                        Travaillons ensemble
                    </a>
                </motion.div>
            </div>
        </header>
    );
};

export default Header;