import React from 'react';
import { motion } from 'framer-motion';
import Footer from '../../components/module/Footer';
import Logo from '../../../dist/icons/Logo';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <>
            <header className='iit-ui-error__header'>
                <Link to={'/'}>
                    <Logo/>
                </Link>
            </header>
            <div className='iit-ui-error'>
                <div className='iit-ui-error__container'>
                    <div className='iit-ui-error__text'>
                        <motion.p
                        initial={{y: 80, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    // delay: 4.5
                                },
                            
                            }
                        }
                        >Erreur 404</motion.p>
                    </div>
                    <div className='iit-ui-error__text'>
                        <motion.h1
                        initial={{y: 80, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 0.8
                                },
                            }
                        }
                        >La page est introuvable</motion.h1>
                    </div>

                    <div className='iit-ui-error__text'>
                        <motion.div 
                        initial={{y: 80, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 1.3
                                },
                            }
                        }
                        className='iit-ui-error__btn'>
                            <Link to={'/'}>
                              <span>Retour vers la page d'accueil</span>  
                            </Link>
                        </motion.div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default PageNotFound;