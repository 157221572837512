import React from 'react';

const DevelopmentIcon = () => {
    return (
        <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M512 79.89V431.89C511.701 432.474 511.454 433.083 511.26 433.71C504.21 465.09 483.34 481.83 451.26 481.83H195.27C149.44 481.83 103.62 481.94 57.7899 481.83C25.3999 481.66 0.15991 457.12 0.10991 425.2C-0.0634235 312.4 -0.0100902 199.59 0.26991 86.77C0.346022 78.6244 2.14903 70.5875 5.55991 63.19C16.1699 40.62 34.8999 30 59.6799 30C190.493 30 321.303 30 452.11 30C453.94 30 455.78 30 457.6 30.11C480.67 32 497.42 43.17 507.12 64.25C509.38 69.17 510.41 74.66 512 79.89ZM17.2499 165.74C17.1499 167.45 16.9999 168.91 16.9999 170.37C16.9999 254.51 16.9999 338.647 16.9999 422.78C16.9999 447.88 33.9999 464.83 59.0699 464.83C190.35 464.877 321.637 464.877 452.93 464.83C457.089 464.886 461.237 464.398 465.27 463.38C484.1 458.27 495.03 443.09 495.03 422.38C495.03 338.573 495.03 254.77 495.03 170.97V165.81L17.2499 165.74ZM495.05 148.33C495.05 128.24 495.05 108.76 495.05 89.27C495 63.92 478.07 47 452.68 47C321.56 46.9333 190.41 46.9333 59.2299 47C33.8899 47 17.0699 64 16.9999 89.41C16.9466 107.41 16.9466 125.41 16.9999 143.41C16.9999 145 17.1799 146.6 17.2899 148.34L495.05 148.33Z" fill="black"/>
            <path id="Vector_2" d="M279 224.93C277 234.4 274.89 244.82 272.7 255.23C263.8 297.543 254.9 339.85 246 382.15C245.72 383.45 245.45 384.76 245.11 386.04C243.68 391.45 239.5 394.44 234.97 393.32C230.11 392.12 227.56 388 228.64 382.55C230.72 372.11 232.94 361.71 235.14 351.29C244.093 308.817 253.046 266.35 262 223.89C262.84 219.89 264.34 216.56 268.56 215.28C273.94 213.63 279.09 217.91 279 224.93Z" fill="#00EC0F"/>
            <path id="Vector_3" d="M160.42 303.67L196.52 334.41C199.94 337.33 203.46 340.14 206.77 343.18C210.9 346.96 211.46 352.04 208.37 355.59C205.14 359.31 200.23 359.68 195.91 356.03C178.083 341.003 160.33 325.887 142.65 310.68C137.57 306.31 137.65 300.98 142.79 296.53C160.317 281.41 177.933 266.41 195.64 251.53C200.2 247.69 205.34 248 208.48 251.86C211.62 255.72 210.81 260.68 206.27 264.58C193 275.98 179.66 287.28 166.35 298.58C164.58 300.08 162.82 301.6 160.42 303.67Z" fill="#00EC0F"/>
            <path id="Vector_4" d="M351.5 303.55L319.41 276.16C314.85 272.27 310.22 268.45 305.73 264.49C301.24 260.53 300.5 255.55 303.61 251.76C306.72 247.97 311.87 247.66 316.45 251.53C334.01 266.363 351.503 281.267 368.93 296.24C374.55 301.07 374.54 306.24 368.93 311.06C351.503 326.027 334.007 340.927 316.44 355.76C311.86 359.63 306.88 359.41 303.59 355.55C300.47 351.88 301.16 346.66 305.65 342.8C319.54 330.86 333.54 319.03 347.48 307.15C348.76 306.1 349.94 305 351.5 303.55Z" fill="#00EC0F"/>
            <path id="Vector_5" d="M35.6699 98C35.6671 90.9382 38.4567 84.1619 43.4305 79.1488C48.4042 74.1358 55.1584 71.2928 62.2199 71.24C67.5185 71.2004 72.7095 72.7353 77.1345 75.65C81.5595 78.5647 85.0192 82.7279 87.0749 87.6116C89.1305 92.4953 89.6894 97.8795 88.6808 103.081C87.6721 108.283 85.1414 113.068 81.4094 116.83C77.6774 120.591 72.9124 123.16 67.7187 124.209C62.525 125.259 57.1366 124.742 52.2368 122.725C47.337 120.708 43.1467 117.281 40.1972 112.879C37.2478 108.477 35.6721 103.299 35.6699 98ZM62.4899 88.2C59.9354 88.2031 57.4835 89.2056 55.6586 90.9931C53.8336 92.7805 52.7805 95.2111 52.7243 97.7649C52.6681 100.319 53.6134 102.793 55.358 104.659C57.1026 106.525 59.508 107.635 62.0599 107.75C64.6144 107.747 67.0663 106.744 68.8913 104.957C70.7162 103.169 71.7694 100.739 71.8256 98.1851C71.8817 95.6312 70.9365 93.1567 69.1919 91.2907C67.4473 89.4248 65.0418 88.3155 62.4899 88.2Z" fill="black"/>
            <path id="Vector_6" d="M253.24 98.6699C253.2 103.937 251.599 109.074 248.64 113.431C245.681 117.789 241.495 121.17 236.614 123.149C231.732 125.128 226.374 125.614 221.216 124.547C216.058 123.48 211.332 120.907 207.636 117.154C203.94 113.401 201.441 108.636 200.453 103.462C199.465 98.2882 200.034 92.9377 202.088 88.0872C204.141 83.2367 207.587 79.1041 211.989 76.2121C216.392 73.3201 221.553 71.7985 226.82 71.8399C230.321 71.8502 233.786 72.5542 237.013 73.9113C240.241 75.2684 243.167 77.2516 245.624 79.7463C248.081 82.2411 250.019 85.1979 251.326 88.446C252.633 91.694 253.284 95.169 253.24 98.6699ZM236.24 98.6699C236.295 96.77 235.788 94.8962 234.782 93.2836C233.776 91.6709 232.316 90.3913 230.585 89.6052C228.855 88.819 226.931 88.5615 225.054 88.8647C223.178 89.168 221.433 90.0187 220.038 91.3099C218.643 92.6012 217.661 94.2756 217.214 96.123C216.768 97.9705 216.876 99.9087 217.527 101.695C218.178 103.48 219.341 105.034 220.872 106.161C222.402 107.289 224.231 107.939 226.13 108.03C227.414 108.117 228.703 107.942 229.918 107.515C231.133 107.089 232.248 106.42 233.196 105.549C234.145 104.679 234.906 103.624 235.435 102.45C235.963 101.276 236.247 100.007 236.27 98.7199L236.24 98.6699Z" fill="black"/>
            <path id="Vector_7" d="M117.86 98.2099C117.874 92.9463 119.448 87.8049 122.383 83.4356C125.318 79.0663 129.483 75.6653 134.351 73.6624C139.218 71.6595 144.57 71.1447 149.731 72.1829C154.891 73.2212 159.628 75.766 163.342 79.4957C167.056 83.2253 169.581 87.9724 170.598 93.1368C171.615 98.3013 171.078 103.651 169.055 108.511C167.032 113.37 163.614 117.521 159.233 120.438C154.851 123.355 149.704 124.908 144.44 124.9C140.937 124.912 137.466 124.229 134.229 122.891C130.991 121.552 128.051 119.585 125.579 117.103C123.107 114.62 121.152 111.672 119.827 108.429C118.502 105.187 117.833 101.713 117.86 98.2099ZM134.86 98.0799C134.828 99.3713 135.056 100.656 135.531 101.857C136.007 103.058 136.719 104.151 137.627 105.071C138.534 105.99 139.617 106.717 140.812 107.208C142.007 107.7 143.288 107.945 144.58 107.93C145.875 107.955 147.163 107.72 148.366 107.238C149.569 106.756 150.663 106.038 151.583 105.126C152.503 104.213 153.231 103.125 153.723 101.927C154.215 100.728 154.461 99.4424 154.447 98.1467C154.433 96.8509 154.159 95.5711 153.641 94.3833C153.123 93.1954 152.372 92.1236 151.432 91.2314C150.493 90.3392 149.383 89.6448 148.17 89.1894C146.957 88.7339 145.665 88.5266 144.37 88.5799C141.847 88.5772 139.426 89.5763 137.639 91.3576C135.852 93.1389 134.845 95.5568 134.84 98.0799H134.86Z" fill="black"/>
        </svg>

    );
};

export default DevelopmentIcon;