import React, {useState} from 'react';
import Arrow from '../../../dist/icons/Arrow';
const Footer = () => {
    const [visible, setVisible] = useState(false);
    const isVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 100){
            setVisible(true)
        } 
        else if (scrolled <= 100){
            setVisible(false)
        }
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    }
    window.addEventListener('scroll', isVisible);
    return (
        <footer className='iit-ui-footer'>
            <div className='iit-ui-scrollTop' onClick={scrollToTop} style={{opacity: visible ? 1 : 0}}>
                <Arrow/>
            </div>
            <div className='iit-ui-footer__container'>
                <p>© 2009 - 2022 IventIT. Tous droits reservés.</p>
                <p><a href="#facebooklink" target={'_blank'}  rel={'noreferrer'}>Rejoigner la communauté sur Facebook</a></p>
            </div>
        </footer>
    );
};

export default Footer;