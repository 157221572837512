import React, {useEffect} from 'react';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CollaboratifIcon from '../../../../dist/icons/service/CollaboratifIcon';
import ConsultingIcon from '../../../../dist/icons/service/ConsultingIcon';
import DataIcon from '../../../../dist/icons/service/DataIcon';
import DevelopmentIcon from '../../../../dist/icons/service/DevelopmentIcon';
import FormationIcon from '../../../../dist/icons/service/FormationIcon';
import Infrastructure from '../../../../dist/icons/service/Infrastructure';

gsap.registerPlugin(ScrollTrigger);
const Services = () => {
    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 991px)" : function(){
                gsap.from(".iit-ui-home-services__subtext.top", {
                    y: -80,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__services",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-services__subtext.bottom", {
                    y: 80,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__services",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-services__grid-box", {
                    y: 100,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__services",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
            }
        })
    }, []);
    return (
        <section className='iit-ui-home__services' id='services'>
            <div className='iit-ui-home-services__container'>
                <div className='iit-ui-home-services__subtext top'>
                    <div className='iit-ui-home-services-subtext__text'>  
                        <span>Nos Services</span>
                    </div>
                    <div className='iit-ui-home-services-subtext__text'>  
                        <span>Nos Services</span>
                    </div>
                    <div className='iit-ui-home-services-subtext__text'>  
                        <span>Nos Services</span>
                    </div>
                </div>
                <div className='iit-ui-home-services__subtext bottom'>
                    <div className='iit-ui-home-services-subtext__text'>  
                        <span>Nos Services</span>
                    </div>
                    <div className='iit-ui-home-services-subtext__text'>  
                        <span>Nos Services</span>
                    </div>
                    <div className='iit-ui-home-services-subtext__text'>  
                        <span>Nos Services</span>
                    </div>
                </div>
                <div className='iit-ui-home-services__grid-container'>
                    <div className='iit-ui-home-services__grid'>
                        <div className='iit-ui-home-services__grid-box'>
                            <ConsultingIcon/>
                            <div className='iit-ui-home-services-grid__text'>
                                <strong>Consulting</strong>
                            </div>
                        </div>
                        <div className='iit-ui-home-services__grid-box'>
                            <DevelopmentIcon/>
                            <div className='iit-ui-home-services-grid__text'>
                                <strong>Developpement</strong>
                            </div>
                        </div>
                        <div className='iit-ui-home-services__grid-box'>
                            <DataIcon/>
                            <div className='iit-ui-home-services-grid__text'>
                                <strong>Data & Decisionnel</strong>
                            </div>
                        </div>
                        <div className='iit-ui-home-services__grid-box'>
                            <CollaboratifIcon/>
                            <div className='iit-ui-home-services-grid__text'>
                                <strong>Portails & Collaboratif</strong>
                            </div>
                        </div>
                        <div className='iit-ui-home-services__grid-box'>
                            <Infrastructure/>
                            <div className='iit-ui-home-services-grid__text'>
                                <strong>Infrastructure</strong>
                            </div>
                        </div>
                        <div className='iit-ui-home-services__grid-box'>
                            <FormationIcon/>
                            <div className='iit-ui-home-services-grid__text'>
                                <strong>Formations</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;