import React, {useEffect} from 'react';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import image from '../../../../dist/images/pexels-andres-ayrton-6579052.jpg';

gsap.registerPlugin(ScrollTrigger);
const About = () => {
    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 991px)" : function(){
                gsap.from(".iit-ui-home-about-content__image", {
                    y: 400,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__about",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-about-subtext__text", {
                    y: -100,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__about",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-about-content__title h1", {
                    y: 300,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__about",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
                gsap.from(".iit-ui-home-about-content__paragraph p", {
                    y: 300,
                    opacity: 0,
                    ease: "none",
                    scrollTrigger: {
                        trigger: ".iit-ui-home__about",
                        start: "top center",
                        end: "bottom bottom",
                        scrub: true,
                    }, 
                });
            }
        })
    }, []);
    return (
        <section className='iit-ui-home__about' id='about'>
            <div className='iit-ui-home-about__container'>
                <div className='iit-ui-home-about__subtext'>
                    <div className='iit-ui-home-about-subtext__text'>  
                        <span>A propos</span>
                    </div>
                    <div className='iit-ui-home-about-subtext__text'>  
                        <span>A propos</span>
                    </div>
                    <div className='iit-ui-home-about-subtext__text'>  
                        <span>A propos</span>
                    </div>
                </div>
                <div className='iit-ui-home-about__content'>
                    <div className='iit-ui-home-about-content__image'>
                        <img src={image} alt="" />
                    </div>
                    <div className='iit-ui-home-about-content__text'>
                        <div className='iit-ui-home-about-content__title'>
                            <h1>Qui sommes-nous</h1>
                        </div>
                        <div className='iit-ui-home-about-content__paragraph'>
                            <p>
                                Nous sommes une équipe tournés vers l’avenir grâce au web et de l’open source, Nous guidons les entreprises dans la mise en place et le déploiement de leurs systèmes d’information. Tous nos collaborateurs contribuent jour après jour à la force de iventIT. Une équipe de passionnés avec des profils complémentaires pour le management des SSII.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;