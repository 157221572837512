import React, { useState } from 'react';
import SmoothScroll from 'smooth-scroll';
import { Route, Routes } from 'react-router-dom';
import './dist/styles/index.scss';
import HomePage from './views/pages/home/HomePage';
import PageNotFound from './views/pages/errors/PageNotFound';
import Loader from './views/pages/home/modules/Loader';

function App() {
  const [loading, setLoading] = useState(true);
  new SmoothScroll('a[href*="#"]', {
      speed: 500,
      speedAsDuration: true
  });
  return (
    <div className="App">
        <Routes>
          <Route exact path={'/'} element={
            loading ? (
              <Loader setLoading={setLoading}/>
            ) : (
              <HomePage/>
            )
          }/>
          <Route  path="/*"  element={
            <PageNotFound/>
          } />
        </Routes>
    </div>
  );
}

export default App;
