import React from 'react';
import armoirie from '../../../../dist/images/partenaire/burkina.jpeg';
import caem from '../../../../dist/images/partenaire/caem.jpeg';
import cil from '../../../../dist/images/partenaire/cil.jpeg';
import dgttm from '../../../../dist/images/partenaire/dgttm.png';
import laposte from '../../../../dist/images/partenaire/laposte.jpeg';
import sonabel from '../../../../dist/images/partenaire/sonabel.png';
import uemoa from '../../../../dist/images/partenaire/uemoa.png';
import acad from '../../../../dist/images/partenaire/acad.jpeg';
import afp from '../../../../dist/images/partenaire/afp.jpeg';
import africom from '../../../../dist/images/partenaire/africom.jpeg';
import aged from '../../../../dist/images/partenaire/aged.jpeg';
import baara from '../../../../dist/images/partenaire/baara.jpeg';
import cilss from '../../../../dist/images/partenaire/cilss.jpeg';
import arfo from '../../../../dist/images/partenaire/arfo.jpeg';
import praps from '../../../../dist/images/partenaire/praps.jpeg';
import scpa from '../../../../dist/images/partenaire/scpa.jpeg';
import tovio from '../../../../dist/images/partenaire/tovio.jpeg';
const Partner = () => {
    return (
        <section className='iit-ui-home__partner'>
            <div className='iit-ui-home-partner__container'>
                <div className='iit-ui-home-partner__title'>
                    <h1>Ils nous font confiance</h1>
                </div>
                <div className='iit-ui-home-partner__grid'>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={armoirie} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={uemoa} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={dgttm} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={laposte} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={cil} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={sonabel} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={acad} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={caem} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={africom} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={aged} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={afp} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={baara} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={cilss} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={arfo} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={praps} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={scpa} alt="" />
                    </div>
                    <div className='iit-ui-home-partner__gridBox'>
                        <img src={tovio} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partner;