import React from 'react';

const Infrastructure = () => {
    return (
        <svg width="512" height="513" viewBox="0 0 512 513" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M227.79 264.2C243.79 285.07 243.79 313.62 227.85 332.66C228.891 333.754 229.818 334.95 230.62 336.23C233.23 341.39 237.24 346.47 237.84 351.9C238.97 362.03 239.27 372.56 237.76 382.61C235.42 398.16 221.49 409.39 205.76 409.46C162.427 409.673 119.07 409.673 75.69 409.46C58.27 409.36 43.69 395.09 42.77 377.85C42.4 370.568 42.4 363.272 42.77 355.99C43.2 346.93 47.35 339.38 53.64 333.11C37.81 310.51 37.81 285.02 53.77 264.18C38.77 250.1 41.48 231.83 43.77 214.37C45.84 198.87 60.19 187.79 75.93 187.73C119.11 187.563 162.29 187.563 205.47 187.73C223.61 187.82 238.21 202.55 238.78 220.64C238.98 227.03 238.94 233.44 238.78 239.83C238.61 249.36 234.62 257.3 227.79 264.2ZM141 255.92C162 255.92 182.94 255.98 203.91 255.92C214.56 255.87 221.68 248.81 221.91 238.29C222 232.78 222.04 227.29 221.91 221.76C221.64 212.13 214.54 204.76 204.91 204.76C162.243 204.647 119.577 204.647 76.91 204.76C67.05 204.76 59.97 212.16 59.75 222.1C59.65 226.89 59.75 231.69 59.75 236.49C59.75 249.15 66.51 255.9 79.15 255.92C99.7167 255.973 120.333 255.973 141 255.92ZM140.72 324.2C161.87 324.2 183.02 324.28 204.17 324.2C214.51 324.15 221.64 317.07 221.86 306.86C221.98 301.17 222.02 295.47 221.86 289.79C221.55 280.46 214.47 273.12 205.18 273.1C162.353 272.96 119.523 272.96 76.69 273.1C67.13 273.1 60.05 280.53 59.8 290.16C59.67 295.16 59.8 300.11 59.8 305.09C59.8 317.37 66.66 324.23 78.93 324.25C99.4767 324.25 120.057 324.233 140.67 324.2H140.72ZM140.97 392.49C161.77 392.49 182.56 392.49 203.35 392.49C214.93 392.49 221.8 385.49 221.88 373.81C221.88 368.48 222.04 363.14 221.82 357.81C221.46 348.75 214.46 341.42 205.44 341.39C162.433 341.23 119.433 341.23 76.44 341.39C67.16 341.39 60.11 348.82 59.81 358.15C59.65 363.15 59.76 368.1 59.81 373.08C59.81 385.74 66.56 392.51 79.19 392.52C99.73 392.527 120.307 392.517 140.92 392.49H140.97Z" fill="#00EC0F"/>
            <path id="Vector_2" d="M153.53 105.15L138 99.2998L143 86.3998C126.74 81.3998 104.6 93.2298 97.9999 110.58L111.53 111.2C111.06 116.94 110.63 122.2 110.18 127.79C73.3599 126.88 44.4199 140.79 27.1799 173.52C9.93991 206.25 14.5699 237.64 36.2899 267.73L23.5399 278.33C14.7167 268.015 8.18882 255.941 4.38991 242.91C-12.0001 187.62 18.8599 131.25 74.2899 114.7C75.4452 114.292 76.505 113.652 77.4043 112.82C78.3036 111.987 79.0235 110.98 79.5199 109.86C89.7099 80.2798 117.67 63.6198 148.29 69.4798C151.84 70.1598 153.29 69.0698 155.1 66.4798C191.61 16.4798 241.1 -6.75016 302.17 1.65984C365.6 10.4098 407.4 47.9198 428.28 108.43C429.56 112.12 431.48 113.24 434.81 114.07C501.97 130.88 533.28 209.34 496.21 267.79C495.36 269.13 494.42 270.42 493.3 272.04L479.61 262.44C497.85 233.56 500.36 203.87 484.9 173.63C474.25 152.8 457.14 139.06 434.02 131.51C434.48 139.07 434.9 145.88 435.34 153.11H418.34C417.23 113.7 402.95 79.9298 373.85 52.9298C352.88 33.4998 328 21.9998 299.78 18.1598C239.9 9.99984 178.41 42.6098 153.53 105.15Z" fill="black"/>
            <path id="Vector_3" d="M401.23 349.81C390.23 349.81 379.23 349.86 368.23 349.81C347.6 349.7 332.92 335.15 332.82 314.64C332.74 298.12 332.74 281.6 332.82 265.08C332.95 245.16 347.66 230.45 367.57 230.35C389.943 230.25 412.32 230.25 434.7 230.35C454.31 230.45 469.11 245.14 469.29 264.69C469.443 281.563 469.443 298.437 469.29 315.31C469.13 334.9 454.39 349.59 434.81 349.79C423.6 349.9 412.42 349.81 401.23 349.81ZM401 332.73C412.38 332.73 423.75 332.87 435.13 332.73C437.365 332.748 439.582 332.324 441.652 331.482C443.722 330.639 445.605 329.395 447.192 327.821C448.779 326.247 450.038 324.374 450.898 322.311C451.758 320.248 452.2 318.035 452.2 315.8C452.37 310.12 452.35 304.42 452.2 298.73C451.96 288.83 444.81 281.73 434.86 281.56C428.65 281.44 422.41 281.71 416.2 281.48C403.3 280.99 393.52 275.12 387.04 263.9C385.8 261.75 384.81 259.46 383.63 257.27C379.34 249.37 371.37 245.73 363.02 247.83C354.93 249.83 349.88 256.58 349.85 265.6C349.79 281.96 349.79 298.317 349.85 314.67C349.91 325.55 357.03 332.67 367.93 332.72C378.83 332.77 390 332.74 401 332.73ZM451.7 268.73C453.22 257.66 447.48 248.32 437.7 247.73C424.7 246.95 411.63 247.54 398.46 247.54C402.85 260.63 408.02 264.37 420.98 264.46C431.41 264.55 442 263.16 451.71 268.75L451.7 268.73Z" fill="#00EC0F"/>
            <path id="Vector_4" d="M400.88 512C389.88 512 378.88 512.07 367.88 512C347.64 511.86 332.94 497.22 332.82 477C332.727 460.48 332.727 443.96 332.82 427.44C332.94 407.23 347.65 392.59 367.92 392.52C390.12 392.44 412.32 392.44 434.52 392.52C454.44 392.62 469.16 407.31 469.31 427.22C469.43 443.887 469.43 460.583 469.31 477.31C469.16 497.18 454.41 511.81 434.48 511.98C423.26 512.06 412.07 512 400.88 512ZM400.88 494.92C412.26 494.92 423.63 495.05 435.01 494.92C444.96 494.78 452.01 487.62 452.24 477.65C452.36 472.65 452.29 467.65 452.24 462.71C452.17 450.71 445.33 443.84 433.44 443.71C426.5 443.66 419.5 444.2 412.66 443.38C398.42 441.66 389.66 432.8 383.86 420.11C379.93 411.52 371.75 407.91 363.19 409.97C354.92 411.97 349.88 418.78 349.85 428.16C349.81 444.16 349.81 460.16 349.85 476.16C349.85 488.03 356.85 494.87 368.85 494.92C379.52 494.94 390.18 494.91 400.85 494.9L400.88 494.92ZM451.88 431.06C453.07 417.48 446.07 409.65 433.28 409.58H398.28C403.58 423.58 407.99 426.58 421.77 426.67C431.8 426.7 442 425.44 451.88 431V431.06Z" fill="#00EC0F"/>
            <path id="Vector_5" d="M273 298.72H247.82V281.85H315.45V298.44H290.45V452.13H315.37V469.18C303.67 469.18 291.99 469.51 280.37 469.02C275.53 468.82 273.14 464.95 273.12 460.02C273.12 452.56 273.12 445.09 273.12 437.62V298.72H273Z" fill="black"/>
            <path id="Vector_6" d="M204.35 238.66H153.89V222.05H204.35V238.66Z" fill="#00EC0F"/>
            <path id="Vector_7" d="M110.86 230.38C110.846 232.059 110.335 233.696 109.391 235.084C108.447 236.472 107.113 237.549 105.557 238.179C104.001 238.809 102.293 238.964 100.649 238.624C99.005 238.283 97.499 237.463 96.321 236.267C95.1431 235.071 94.3462 233.553 94.0311 231.904C93.7159 230.255 93.8967 228.55 94.5505 227.003C95.2042 225.457 96.3017 224.14 97.7042 223.217C99.1067 222.294 100.751 221.808 102.43 221.82C104.674 221.864 106.812 222.785 108.387 224.384C109.963 225.983 110.85 228.135 110.86 230.38Z" fill="#00EC0F"/>
            <path id="Vector_8" d="M128.11 221.82C129.792 221.846 131.428 222.37 132.811 223.326C134.195 224.283 135.264 225.628 135.882 227.192C136.501 228.756 136.642 230.468 136.287 232.112C135.932 233.756 135.098 235.258 133.889 236.428C132.68 237.597 131.152 238.382 129.497 238.682C127.842 238.983 126.135 238.785 124.593 238.115C123.05 237.445 121.741 236.333 120.831 234.919C119.92 233.504 119.45 231.852 119.48 230.17C119.542 227.927 120.481 225.797 122.093 224.236C123.706 222.676 125.866 221.809 128.11 221.82Z" fill="#00EC0F"/>
            <path id="Vector_9" d="M204.52 290.43V306.87H154V290.43H204.52Z" fill="#00EC0F"/>
            <path id="Vector_10" d="M93.8799 298.48C93.9038 296.801 94.425 295.167 95.3776 293.784C96.3302 292.402 97.6714 291.332 99.2316 290.712C100.792 290.091 102.501 289.947 104.143 290.298C105.785 290.649 107.286 291.479 108.457 292.683C109.627 293.887 110.414 295.411 110.718 297.062C111.023 298.713 110.831 300.418 110.166 301.96C109.502 303.502 108.395 304.812 106.986 305.726C105.577 306.639 103.929 307.114 102.25 307.09C100.003 307.043 97.8654 306.113 96.299 304.502C94.7327 302.89 93.8636 300.727 93.8799 298.48Z" fill="#00EC0F"/>
            <path id="Vector_11" d="M136.46 299C136.379 300.679 135.802 302.296 134.801 303.648C133.801 304.999 132.422 306.023 130.84 306.591C129.258 307.158 127.542 307.244 125.911 306.837C124.28 306.43 122.806 305.548 121.676 304.303C120.546 303.059 119.811 301.507 119.563 299.844C119.315 298.181 119.565 296.482 120.283 294.962C121 293.442 122.153 292.168 123.594 291.303C125.035 290.437 126.701 290.019 128.38 290.1C130.619 290.242 132.716 291.25 134.224 292.912C135.733 294.573 136.535 296.757 136.46 299Z" fill="#00EC0F"/>
            <path id="Vector_12" d="M204.53 358.72V375.14H154V358.72H204.53Z" fill="#00EC0F"/>
            <path id="Vector_13" d="M102.48 358.39C104.162 358.41 105.801 358.928 107.188 359.88C108.575 360.832 109.649 362.174 110.273 363.736C110.897 365.299 111.043 367.011 110.693 368.657C110.343 370.302 109.513 371.807 108.307 372.98C107.102 374.154 105.575 374.943 103.921 375.248C102.266 375.553 100.558 375.361 99.0135 374.694C97.4686 374.028 96.1561 372.919 95.2423 371.506C94.3284 370.094 93.8543 368.442 93.8799 366.76C93.9348 364.517 94.8657 362.385 96.4731 360.821C98.0806 359.257 100.237 358.384 102.48 358.39Z" fill="#00EC0F"/>
            <path id="Vector_14" d="M136.46 366.83C136.47 368.509 135.981 370.153 135.057 371.555C134.132 372.956 132.813 374.052 131.265 374.704C129.718 375.356 128.011 375.534 126.363 375.216C124.714 374.898 123.196 374.098 122.002 372.918C120.808 371.738 119.99 370.23 119.653 368.585C119.316 366.94 119.474 365.232 120.107 363.677C120.741 362.122 121.821 360.789 123.212 359.848C124.602 358.907 126.241 358.4 127.92 358.39C130.163 358.405 132.311 359.296 133.907 360.873C135.502 362.449 136.418 364.587 136.46 366.83Z" fill="#00EC0F"/>
        </svg>

    );
};

export default Infrastructure;