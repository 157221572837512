import React from 'react';
import Footer from './module/Footer';
import Header from './module/Header';

const Layout = ({children}) => {
    return (
        <>
          <Header/>
            <main className='iit-ui-main' role={'main'}>
                {children}
            </main>
          <Footer/>  
        </>
    );
};

export default Layout;