import React from 'react';
import { motion } from 'framer-motion';
import image1 from '../../../../dist/images/bg/pexels-andres-ayrton-6579062.jpg';
import image2 from '../../../../dist/images/bg/christina-wocintechchat-com-YPcnjFweo40-unsplash.jpg';
import image3 from '../../../../dist/images/pexels-monstera-9480114.jpg';
const Banner = () => {
    return (
        <section className='iit-ui-home__banner' id='accueil'>
            <div className='iit-ui-home-banner__container'>
                <div className='iit-ui-home-banner__text'>
                    <div className='iit-ui-home-banner__subtext'>
                        <motion.div 
                        initial={{y: -80, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 3
                                },
                            
                            }
                        }
                        className='iit-ui-home-banner-subtext__text'>  
                            <span>Bienvenue</span>
                        </motion.div>
                        <motion.div 
                        initial={{y: -80, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 3.6
                                },
                            
                            }
                        }
                        className='iit-ui-home-banner-subtext__text'>  
                            <span>Bienvenue</span>
                        </motion.div>
                        <motion.div 
                        initial={{y: -80, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 3.8
                                },
                            
                            }
                        }
                        className='iit-ui-home-banner-subtext__text'>  
                            <span>Bienvenue</span>
                        </motion.div>
                    </div>
                    <div className='iit-ui-home-banner-text__container'>
                        <div className='iit-ui-home-banner-text__subtitle'>
                            <motion.p
                             initial={{y: 30, opacity: 0}}
                             animate={
                                 {
                                    y: 0, 
                                    opacity: 1,
                                     transition: {
                                         ease: [0.6, 0.01, -0.25, 0.95],
                                         duration: 1.5,
                                         delay: 4.6
                                     },
                                 
                                 }
                             }
                            >Bienvenue sur Iventit</motion.p>
                        </div>
                        <div className='iit-ui-home-banner-text__title'>
                            <motion.h1
                            initial={{y: 30, opacity: 0}}
                            animate={
                                {
                                   y: 0, 
                                   opacity: 1,
                                    transition: {
                                        ease: [0.6, 0.01, -0.25, 0.95],
                                        duration: 1.5,
                                        delay: 5
                                    },
                                
                                }
                            }
                            >Accompagner et former dans la transformation numérique</motion.h1>
                        </div>
                    </div>
                    <motion.div 
                     initial={{scaleY: 0}}
                     animate={
                         {
                             scaleY: 1,
                             transition: {
                                 ease: [0.6, 0.01, -0.25, 0.95],
                                 duration: 1.5,
                                 delay: 5
                             },
                         
                         }
                     }
                    className='iit-ui-vertical-bar'></motion.div>
                    <div className='iit-ui-scrollDown'>
                        <motion.p
                        initial={{y: 80, opacity: 0}}
                        animate={
                            {
                                y: 0, 
                                opacity: 1,
                                transition: {
                                    ease: [0.6, 0.01, -0.25, 0.95],
                                    duration: 1.5,
                                    delay: 5
                                },
                            
                            }
                        }
                        >Défiler vers le bas</motion.p>
                    </div>
                </div>
                <div className='iit-ui-home-banner__images'>
                    <div className='iit-ui-home-banner-images__container'>
                        <div 
                            className='iit-ui-home-banner-image'>
                            <img src={image1} alt="" />
                            <div className='iit-ui-home-banner-image__text'>
                                <p>IT</p>
                            </div>
                            <motion.div 
                             initial={{scaleY: 1}}
                             animate={
                                 {
                                     scaleY: 0,
                                     transition: {
                                         ease: [0.6, 0.01, -0.25, 0.95],
                                         duration: 1.5,
                                         delay: 2.0
                                     },
                                 
                                 }
                             }
                            className='iit-ui-blank'>
                            </motion.div>
                        </div>
                        <div 
                            className='iit-ui-home-banner-image'>
                            <img src={image2} alt="" />
                            <div className='iit-ui-home-banner-image__text'>
                                <p>FOR</p>
                            </div>
                            <motion.div 
                             initial={{scaleY: 1,}}
                             animate={
                                 {
                                    scaleY: 0,
                                    transition: {
                                         ease: [0.6, 0.01, -0.25, 0.95],
                                         duration: 1.5,
                                         delay: 1.5
                                    },
                                 
                                 }
                             }
                            className='iit-ui-blank'>
                            </motion.div>
                        </div>
                        <div 
                           
                            className='iit-ui-home-banner-image'>
                            <img src={image3} alt="" />
                            <div className='iit-ui-home-banner-image__text'>
                                <p>ALL</p>
                            </div>
                            <motion.div 
                             initial={{scaleY: 1,}}
                             animate={
                                 {
                                    scaleY: 0,
                                     transition: {
                                         ease: [0.6, 0.01, -0.25, 0.95],
                                         duration: 1.5,
                                         delay: 1
                                     },
                                 
                                 }
                             }
                            className='iit-ui-blank'>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;