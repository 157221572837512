import React from 'react';
import Layout from '../../components/Layout';
import About from './modules/About';
import Banner from './modules/Banner';
import Contacts from './modules/Contacts';
import Objectif from './modules/Objectif';
import Partner from './modules/Partner';
import Services from './modules/Services';

const HomePage = () => {
    return (
        <Layout>
            <Banner/>
            <About/>
            <Objectif/>
            <Services/>
            <Contacts/>
            <Partner/>
        </Layout>
    );
};

export default HomePage;